import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

// api
import Api from '../../api/api'

// ----------------------------------------------------------------------

export default function DatasetForm() {
  const navigate = useNavigate();
  const [dataset, setDataset] = useState('');
  useEffect(() => {
    Api.get("/get-dataset")
    .then(res => {
      setDataset(res.data.dataset)
  })
},[])

  const handleClick = () => {
    const data = {
      dataset
    }
    Api.post('/update-dataset', data)
      .then(res => {
        console.log(res);
        // navigate('/dashboard/user', { replace: true });
      })
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          label="Dataset"
          multiline
          rows={10} 
          value={dataset}
          onChange={(e) => { setDataset(e.target.value) }}
        />

        <LoadingButton href='/dashboard/user' fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
          Submit
        </LoadingButton>

      </Stack>

    </>
  );
}
