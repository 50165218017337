import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';

// ----------------------------------------------------------------------

export default function Router() {

  function ProtectedRoute({ children }) {
    const token = localStorage.getItem('token');
    console.log(!!token)
    const location = useLocation();

    return !!token ? (
        children
    ) : (
            <Navigate
                to="/"
                state={{ from: location }}
            />
        );
}

  const routes = useRoutes([
    {
      path: '/',
      element: <LoginPage />,
    },
  
      {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <ProtectedRoute ><DashboardAppPage /></ProtectedRoute> },
        { path: 'user', element: <ProtectedRoute ><UserPage /></ProtectedRoute> },
        { path: 'products', element: <ProtectedRoute ><ProductsPage /> </ProtectedRoute> },
        { path: 'blog', element: <ProtectedRoute> <BlogPage /> </ProtectedRoute>},
      ],
    },
  
  
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
