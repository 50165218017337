import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components

// api
import Api from '../../api/api';

// ----------------------------------------------------------------------

export default function SubmitForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission state

  const handleClick = () => {
    setIsSubmitting(true); // Set form submission state to true
    const data = {
      email,
      phone,
      name,
      question
    }
    Api.post('/', data)
      .then(res => {
        console.log(res);
        navigate('/dashboard/user', { replace: true });
      })
      .finally(() => {
        setIsSubmitting(false); // Set form submission state back to false
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e) => { setEmail(e.target.value) }} />
        <TextField name="phone" label="Phone" onChange={(e) => { setPhone(e.target.value) }} />
        <TextField name="name" label="Name" onChange={(e) => { setName(e.target.value) }} />
        <TextField
          label="Question"
          multiline
          rows={4}
          onChange={(e) => { setQuestion(e.target.value) }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleClick}
          loading={isSubmitting} // Set the loading prop to the form submission state
          loadingPosition="start"
          startIcon={<CircularProgress size={20} />} // Display the CircularProgress when submitting
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </LoadingButton>

      </Stack>

    </>
  );
}
