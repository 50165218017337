import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import DatasetForm from '../sections/DatasetForm/DatasetForm'

export default function BlogPage() {
  return (
    <>
      <Helmet>
        <title>Update Dataset</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Update Dataset
          </Typography>
        </Stack>

        <DatasetForm />


      </Container>
    </>
  );
}
