import React, { useState } from 'react';
import { Modal, Typography, TextField, Button, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Api from '../../api/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  height: "60%",
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 10,
  overflow: 'auto',
};

const DetailsModal = ({ row, closeDetails, isOpen }) => {
  const { email, name, phone, question, _id } = row;
  const [answer, setAnswer] = useState(row.answer);
  const [isEditing, setIsEditing] = useState(false);
  const disable = true;

  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    const data = {
      answer
    }
    Api.patch(`/post/${_id}`, data)
      .then(res => {
        console.log(res);
        setIsEditing(false);
      });
  };
  /* eslint-disable no-useless-concat */
  return (
    <Modal
      open={isOpen}
      onClose={closeDetails}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: 'flex',padding:"4%" }}>
          <img src='/assets/images/avatars/avatar_13.jpg'
            width='80px'
            height='80px'
            alt='avatar'
            style={{
              borderRadius: '50%',
            }}
          />

          <div style={{ marginLeft: '10px' }}>
            <Typography variant='h5'>{name} </Typography>
            <Typography variant='subtitle2'>{email} </Typography>
            <Typography variant='subtitle2'>{phone} </Typography>
          </div>
        </div>
        <Typography sx={{ mt: 2, px: 4 }}>
          <strong>Question:</strong> 
          <Typography>
            {question}
          </Typography>
          <br />
          <strong>Answer:</strong>
          {isEditing ? (
            <TextField
              multiline
              value={answer}
              onChange={handleAnswerChange}
              fullWidth
              minRows={5}
            />
          ) : (
            <TextField
              multiline
              value={answer}
              onChange={handleAnswerChange}
              fullWidth
              minRows={5}
              disabled={disable}
            />
          )}
        </Typography>
        <div style={{position:"sticky", bottom:0, backgroundColor: "inherit", width:"100%",justifyContent:'flex-end', display:'flex',paddingRight:"4%"}}>
          {isEditing ? (
            <Button onClick={handleSaveClick} variant="contained" color="primary" sx={{ my: 2 }}>
              Save
            </Button>
          ) : (
            <Button onClick={handleEditClick} variant="outlined" color="primary" sx={{ my: 2 }}>
              Edit Answer
            </Button>
          )}
          <Button onClick={() => { console.log("clicked send"); }} variant="contained" style={{ backgroundColor: "black"}} sx={{ my: 2,ml:2, py: 0.6 }}>
            Send
          </Button>
        </div>
      </Box>
    </Modal>

  );
};

export default DetailsModal;

