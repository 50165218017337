import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Input,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';
import { green } from '@mui/material/colors';
import Iconify from '../components/iconify';

import DetailsModal from '../components/Modal/DetailsModal';

import Api from '../api/api';

export default function Record({ row }) {
  const { email, name, phone, question, answer, _id } = row;
  const [disable, setDisable] = useState(true);
  const [editedAnswer, setEditedAnswer] = useState(answer);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    setEditedAnswer(answer);
  }, [answer]);

  const handleEditClick = () => {
    setDisable(false);
  };

  const handleDetails = () => {
    setShowDetails(true);
  };
  const closeDetails = () => {
    setShowDetails(false);
    window.location.reload();
  };

  const handleSubmit = () => {
    setLoading(true);
    setDisable(true);
    const data = {
      answer: editedAnswer
    }
    Api.patch(`/post/${_id}`, data)
      .then(res => {
        console.log(res);
        setLoading(false);
      })
  }

  const handleAnswerChange = (event) => {
    setEditedAnswer(event.target.value);
  };

  return (
    <TableRow hover key={_id} tabIndex={-1} role="checkbox">
      {/* <TableCell component="th" scope="row" padding="checkbox">
        <Checkbox />
      </TableCell> */}

      <TableCell component="th" scope="row" >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle2" noWrap>
            {email}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">{name}</TableCell>

      <TableCell align="left" sx={{ width: '50px' }}>
        {phone}
      </TableCell>

      <TableCell align="left" style={{ maxWidth: "50px", }} sx={{ overflow: 'hidden' }}>
        {question}
      </TableCell>


      <TableCell align="left">
        <TextField
          value={editedAnswer}
          disabled={disable}
          multiline
          rows={4}
          onChange={handleAnswerChange}
        />
      </TableCell>

      {!loading ? (<TableCell align="left">
        {disable ? (
          <IconButton size="large" color="inherit" onClick={handleEditClick}>
            <Iconify icon={'eva:edit-fill'} />
          </IconButton>
        ) : (
          <IconButton
            size="large"
            color="inherit"
            onClick={handleSubmit}
          >
            <Iconify
              icon={'eva:checkmark-circle-2-fill'}
              sx={{ color: green[500] }}
            />
          </IconButton>
        )}
      </TableCell>) : (
        <TableCell align="left">
          <CircularProgress size={24} color="primary" />
        </TableCell>
      )}
      <TableCell align="left">
        <Button variant="contained" style={{ backgroundColor: "black" }} onClick={handleDetails}>
          Details
        </Button>
      </TableCell>

        {
          showDetails && (
            <DetailsModal row={row} closeDetails={closeDetails} isOpen={showDetails} />
          )
        }


    </TableRow>
  );
}
